import React from "react";
import {
  SEO,
  PageLayout,
  FadeReveal,
  MarkdownContent,
  Image,
  SlideReveal,
  multipleComponentIterator,
  BackgroundImageTeamMember,
  InternalLink,
  BasicCarousel,
  OutboundLink,
} from "@bluefin/components";
import { Grid, Header, Button, p, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
              className={"markdown-column"}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "content_section_header",
                  defaultValue: "About",
                })}
              />
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"image-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "content_section_image",
                    numToSelect: 1,
                  })}
                  background={false}
                  className={"image"}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"about-staff-container"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Header
                as={"h2"}
                className={"about-staff-header"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_header",
                  defaultValue: "OUR TOP STYLISTS",
                })}
              />
              <MarkdownContent
                className={"about-staff-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_description",
                })}
              />
              <Card.Group
                className={"about-featured-staff stylists"}
                itemsPerRow={3}
              >
                <SlideReveal
                  cascade={true}
                  triggerOnce={true}
                  className={"ui card"}
                  fade={true}
                  direction={"up"}
                  children={multipleComponentIterator({
                    iterator: allFishermanBusinessTeam.nodes,
                    limit: 3,
                    components: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                          />
                        ),
                        propMap: {
                          key: "_id",
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  })}
                />
              </Card.Group>
              <Button to={"/team/"} primary={true} as={InternalLink}>
                Meet The Ishdolls
              </Button>
            </Grid.Column>
          </Grid>
          <div className={"product-section"}>
            <Grid
              stackable={true}
              doubling={true}
              columns={2}
              reversed={"mobile"}
            >
              <Grid.Column width={8} className={"image-column"}>
                <div className={"image-container"}>
                  <BasicCarousel
                    className={""}
                    slide={true}
                    size={1}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    animation={{
                      name: "blur",
                      duration: 200,
                      transitionProps: {
                        animating: { blur: "2px", opacity: 0.99 },
                        preAnimating: { blur: "2px", opacity: 0.99 },
                      },
                    }}
                    mobile={{ size: 1 }}
                    items={[
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/db11f39b-d6ad-4037-b260-5b99c1f3ede8/1.jpg"
                          }
                          alt={"The Perfect Daily Shampoo"}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header
                              as={"h3"}
                              content={"The Perfect Daily Shampoo"}
                            />
                          </div>
                          <div>
                            <p>Davines</p>
                            <Header as={"h4"} content={"OI Shampoo"} />
                            <Button
                              primary={true}
                              to={
                                "https://shop.saloninteractive.com/store/salonish/product/6337/9801"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/db11f39b-d6ad-4037-b260-5b99c1f3ede8/2.jpg"
                          }
                          alt={"Rich and Buttery Conditioner"}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header
                              as={"h3"}
                              content={"Rich and Buttery Conditioner"}
                            />
                          </div>
                          <div>
                            <p>Davines</p>
                            <Header as={"h4"} content={"OI Conditioner"} />
                            <Button
                              primary={true}
                              to={
                                "https://shop.saloninteractive.com/store/salonish/product/6319/9783"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/db11f39b-d6ad-4037-b260-5b99c1f3ede8/3.jpg"
                          }
                          alt={"Add Shine and Softness"}
                        />
                        <div className={"image-content"}>
                          <div>
                            <Header
                              as={"h3"}
                              content={"Add Shine and Softness"}
                            />
                          </div>
                          <div>
                            <p>Davines</p>
                            <Header as={"h4"} content={"OI Oil"} />
                            <Button
                              primary={true}
                              to={
                                "https://shop.saloninteractive.com/store/salonish/product/6329/9793"
                              }
                              as={OutboundLink}
                              content={"Shop Now"}
                            />
                          </div>
                        </div>
                      </div>,
                    ]}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={8} className={"product-details-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <Header as={"h2"} content={"Our Favorite Products"} />
                  <p>
                    Looking for salon results every day? Support our small
                    business by buying recommended products directly from us
                    online. We only include brands and products our Ish Dolls
                    would use on our own hair. The best of the best!
                  </p>
                  <Button
                    primary={true}
                    to={"https://shop.saloninteractive.com/store/salonish"}
                    as={OutboundLink}
                    content={"Browse All Products"}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
